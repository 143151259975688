import "../scss/modal.scss";
import 'bootstrap/js/dist/modal';
declare global {
    interface Window {
        $: any;
    }
}

type M2ModalConfiguration = {
    triggerSelector: string;
    delay: number;
    openModalDelay: number;
    iframeResizeDelay: number;
    resizeIframeHeightBuffer: number;
    allowMultiple: boolean;
    defaultModalSize: string;
    isIframe: boolean;
    backdrop: string;
};

interface M2ModalErrorFormHandling {
    ($button: JQuery<HTMLElement>): void;
}

export class M2Modal {

    private readonly defaultConfiguration: M2ModalConfiguration = {
        triggerSelector: ".m2-modal",
        delay: 0,
        openModalDelay: 500,
        iframeResizeDelay: 100,
        resizeIframeHeightBuffer: 5,
        allowMultiple: false,
        defaultModalSize: 'modal-md',
        isIframe: false,
        backdrop: "true"
    };

    private configuration: M2ModalConfiguration;

    constructor(userConfiguration: Partial<M2ModalConfiguration>) {
        this.configuration = { ...this.defaultConfiguration, ...userConfiguration } as M2ModalConfiguration;
        this.setOverlayTrigger();
        this.setModalFormErrorHandling();
    }

    private setOverlayTrigger = () => {
        $(document).on("click", this.configuration.triggerSelector, (e) => {
            e.preventDefault();
            const $this = $(e.target);
            const modalId = $this.data("modal-id") || $this.attr("data-target");
            const modalTitle = $this.data('modal-title');
            const modalSize = $this.data('modal-size') || this.configuration.defaultModalSize;
            const modalDelay = $this.data('modal-delay') || this.configuration.delay;
            const modalBackground = $this.data('modal-background') || 'true';
            const allowMultipleModal = this.configuration.allowMultiple;

            if (this.configuration.isIframe) {
                const iframeUrl = $this.data("modal-url") || $this.attr('href');
                this.create(modalId, modalTitle, iframeUrl, modalSize, null, modalBackground, modalDelay, allowMultipleModal);
            } else {
                const contentTarget = $this.data('modal-content');
                this.create(modalId, modalTitle, contentTarget, modalSize, true, modalBackground, modalDelay, allowMultipleModal);
            }
        });

        $(document).on("click", ".close-iframe-modal", (e) => {
            e.preventDefault();
            // I want this to be a callback method
            this.closeParent();
            this.onClose();
        });
    };

    onClose = () => {
        //console.log("callback?");
    }

    private setModalFormErrorHandling = () => {
        $(document).on('click', '.popup-container input[type=submit]', (e) => {
            const $button = $(e.target);
            this.handleModalFormErrors($button);
        });
    }

    private handleModaliFrameResize = () => {
        const hasIframe = document.querySelector('.iframe-wrapper') !== null;
        if (hasIframe) {
            const iframe = document.querySelector('.iframe-wrapper').querySelectorAll('iframe')[0];
            this.resizeIframe(iframe);
        }
    };

    private create = (modalId: string, modalTitle: string, contentSrc: string, size: string, isInline: boolean, backdrop: string, delay: number, allowMultiple: boolean, callback?: () => void) => {
        if (typeof modalId === "undefined") modalId = 'modal-overlay';
        if (typeof modalTitle === "undefined") modalTitle = null;
        if (typeof contentSrc === "undefined") contentSrc = null;
        if (typeof size === "undefined" || size === null) size = this.configuration.defaultModalSize;
        if (typeof backdrop === "undefined") backdrop = this.configuration.backdrop;
        if (typeof delay === "undefined" || delay === null) delay = this.configuration.delay;
        if (typeof allowMultiple === "undefined") allowMultiple = this.configuration.allowMultiple;
        if (modalId.includes('#')) modalId = modalId.substr(1);

        const jQueryTargetRef = "#" + modalId;
        const modalIsOpen = $('body').hasClass('modal-open');
        if (modalIsOpen && !allowMultiple) {
            this.close();
            delay = this.configuration.openModalDelay;
        }

        setTimeout(() => {
            const $modalContainer = $('<div/>', {
                id: modalId,
                'class': 'modal fade dynamic',
                'role': 'dialog',
                'arial-hidden': true
            });

            const $modalDialog = $('<div/>', {
                'class': 'modal-dialog modal-dialog-centered ' + size,
                'role': 'document'
            });

            $modalContainer.append($modalDialog);

            const $modalContent = $('<div/>', {
                'class': 'modal-content'
            });

            $modalDialog.append($modalContent);

            const $modalHeader = $('<div/>', {
                'class': 'modal-header'
            });

            $modalContent.append($modalHeader);

            if (modalTitle) {
                const $modalTitle = $('<h5/>', {
                    'class': 'modal-title',
                    id: modalId + '_title'
                }).html(modalTitle);
                $modalHeader.append($modalTitle);
            }

            const $closeButton = $('<button/>', {
                'class': 'close',
                'data-dismiss': 'modal',
                'arial-label': 'Close'
            });

            $modalHeader.append($closeButton);

            const $closeIcon = $('<span />', {
                'aria-hidden': true
            }).html('&times');

            $closeButton.append($closeIcon);

            const $modalBody = $('<div/>', {
                id: modalId + '-body',
                'class': 'modal-body'
            });

            $modalContent.append($modalBody);

            if (isInline) {
                $modalBody.addClass('inline-content');
                const $contentSrc = $(contentSrc);
                const $content = $(contentSrc).clone(true);
                $contentSrc.find('input').prop("disabled", true);
                $content.find('input').prop("disabled", false);
                $content.removeClass('hidden');

                const $modalContent = $('<div/>', {
                    'class': 'modal-container'
                });

                const $popContainer = $('<div/>', {
                    'class': 'popup-container'
                });

                const $innerContent = $('<div/>', {
                    'class': 'inner-content'
                });
                $modalContent.appendTo($modalBody);
                $popContainer.appendTo($modalContent);
                $innerContent.appendTo($popContainer);
                $content.appendTo($innerContent);
            } else {
                $modalBody.addClass('iframe-content');

                const $iFrameWrapper = $('<div/>', {
                    'class': 'iframe-wrapper'
                });

                const modaliFrame = document.createElement("iframe") as HTMLIFrameElement;
                modaliFrame.id = "iframe-" + modalId;
                modaliFrame.src = contentSrc;
                modaliFrame.frameBorder = "0";
                modaliFrame.width = '100%';
                modaliFrame.setAttribute('allowFullScreen', 'true');
                modaliFrame.setAttribute('webkitallowfullscreen', 'true');
                modaliFrame.setAttribute('mozallowfullscreen', 'true');
                modaliFrame.setAttribute('allowtransparency', 'true');

                modaliFrame.onload = (e) => {
                    const iframeRef = e.target;
                    this.resizeIframe(iframeRef, this.configuration.iframeResizeDelay);
                };

                $iFrameWrapper.append($(modaliFrame));
                $modalBody.append($iFrameWrapper);
                window.addEventListener('resize', this.handleModaliFrameResize);
            }

            $('body').prepend($modalContainer);

            $(jQueryTargetRef).modal({
                keyboard: false,
                backdrop: backdrop as boolean | "static"
            });

            if (allowMultiple) {
                const $backdrop = $('.modal-backdrop').last();
                const currentModalZ = parseInt($(jQueryTargetRef).css('z-index'));
                const currentBackdropZ = parseInt($backdrop.css('z-index'));
                $(jQueryTargetRef).css('z-index', currentModalZ + 10);
                $backdrop.css('z-index', currentBackdropZ + 10);
            }

            $(jQueryTargetRef).on('hidden.bs.modal',  (e) => {
                const modaliFrame = document.getElementById("iframe-" + modalId);
                if (modaliFrame) window.removeEventListener('resize', this.handleModaliFrameResize);
                $(jQueryTargetRef).remove();
            });

            if (callback) {
                callback();
            }
        }, delay);
    };

    private resizeModal = () => {
        $('.modal').modal('handleUpdate');
    };

    private resizeIframe = (iframe, resizeDelay = 0) => {
        setTimeout(() => {
            try {
                iframe.height = (iframe.contentWindow.document.body.scrollHeight + this.configuration.resizeIframeHeightBuffer) + "px";
            } catch (e) {
                console.log(e);
            }
        }, resizeDelay);
    };

    private resizeParentIframe = (resizeDelay = 0) => {
        setTimeout(() => {
            try {
                const iframe = parent.document.getElementById(window.frameElement.id);
                this.resizeIframe(iframe);
            } catch (e) {
                console.log(e);
            }
        }, resizeDelay);
    };

    public handleModalFormErrors: M2ModalErrorFormHandling = ($button) => {
        const $formFrm = $button.closest('form');
        window.setTimeout(() => {
            if ($formFrm.find('.input-validation-error').length) {
                this.resizeParentIframe();
            }
        }, 300);
    };

    public close = ():void => {
        $('.modal').modal('hide');
    };

    public closeParent = ():void => {
        window.parent.$('.modal').modal('hide');
    };
}