/// <reference types="jquery" />
interface SaveButtonProps {
    formSelector?: string;
    savingLabel?: string;
    savedLabel?: string;
    disableOnClick?: boolean;
    disableSubmit?: boolean;
    disableOnSubmit?: boolean;
}

export class SaveButton {
    private clickCallback: (e: Event, button: JQuery<HTMLElement>) => boolean | void = () => { };
    private submitCallback: (e: Event, button: JQuery<HTMLElement>) => boolean | void = () => { };
    private btnState = {
        saving: "saving",
        saved: "saved",
        disabled: "disabled",
        default: "default",
    };

    private btnLabels = {
        saving: "Saving",
        saved: "Saved",
        default: "Save",
    };

    private $button: JQuery<HTMLElement>;
    private $btnWrapper: JQuery<HTMLElement>;
    private $form: JQuery<HTMLElement>;
    private defaultText: string;
    private savingBtnDelay = 750;
    private saveBtnDelay = 1500;
    private disableSubmit = false;


    constructor(selector: string, options: SaveButtonProps = {}) {
        this.$button = $(selector);
        const btnColor = this.$button.css('color');
        const $btnWrapper = $("<span/>", {
            "class": "button-wrapper",
            "style": "color:" + btnColor
        });
        this.$btnWrapper = this.$button.wrap($btnWrapper).parent();
        this.$form = options.formSelector ? $(options.formSelector) : this.$button.closest("form");
        this.btnLabels.saving = options.savingLabel || this.btnLabels.saving;
        this.btnLabels.saved = options.savedLabel || this.btnLabels.saved;
        this.btnLabels.default = this.$button.is("input") ? this.$button.val().toString() : this.$button.text();

        // Event Options
        const disableOnClick = options.disableOnClick || false;
        const disableOnSubmit = options.disableOnSubmit || false;
        this.disableSubmit = options.disableSubmit || false;

        if (!disableOnClick) {
            this.$button.on("click", (e: Event) => {
                e.preventDefault();
                if (this.$button.attr("data-state") == this.btnState.saved) return false;
                this.clickSuccess(e);
            });
        }

        if (!disableOnSubmit) {
            this.$form.on("submit", (e: Event) => {
                this.submitSuccess(e);
            });
        }
    }

    private setButtonText(text: string) {
        if (this.$button.is("input")) {
            this.$button.val(text);
        } else {
            this.$button.text(text);
        }
    }

    public setState(state: string, delay?: number, callback?: () => void):void {
        this.$button.addClass(this.btnState.disabled);
        this.$button.prop("disabled", true);
        switch (state) {
            case this.btnState.saving:
                this.$button.attr("data-state", this.btnState.saving);
                this.setButtonText(this.btnLabels.saving);
                setTimeout(() => {
                    if (callback) {
                        callback();
                    }
                }, delay || this.savingBtnDelay);
                break;

            case this.btnState.saved:
                this.$button.removeClass(this.btnState.disabled);
                this.$button.attr("data-state", this.btnState.saved);
                this.setButtonText(this.btnLabels.saved);
                this.$button.prop("disabled", true);
                setTimeout(() => {
                    if (callback) {
                        callback();
                    }
                }, delay || this.saveBtnDelay);
                break;

            case this.btnState.disabled:
                this.$button.attr("data-state", this.btnState.disabled);
                this.setButtonText(this.btnLabels.default);
                break;

            default:
                this.$button.removeClass(this.btnState.disabled);
                this.$button.attr("data-state", "");
                this.setButtonText(this.btnLabels.default);
                this.$button.prop("disabled", false);
        }
    }

    public submit(): void {
        this.$form.trigger("submit"); // Trigger the submit event on the form element
    }

    private submitSuccess(e: Event) {
        const allowSubmit = this.submitCallback(e, this.$button);
        if (allowSubmit === false || !this.$form.valid()) {
            this.setState(this.btnState.default);
            return false;
        }
    }

    private clickSuccess(e: Event) {
        this.setState(this.btnState.saving, 1000, () => {
            this.clickCallback(e, this.$button);
            if (!this.disableSubmit) this.submit();
        });
    }

    public onClick(callback: (e: Event, button: JQuery<HTMLElement>) => boolean | void): SaveButton {
        this.clickCallback = (button, e) => callback(button, e);
        return this;
    }

    public onSubmit(callback: (e: Event, button: JQuery<HTMLElement>) => boolean | void): SaveButton {
        this.submitCallback = (e, button) => callback(e, button);
        return this;
    }
}
