﻿import { M2Modal } from '../vendor/M2/Modal/ts/Modal';
import { SaveButton } from "../vendor/M2/formTools/ts/SaveButton";

let saveAddressBtn, cancelOrderBtn;
let modals;

const cardTypes = {
    "visa": "Visa",
    "mastercard": "Master Card",
    "amex": "American Express",
    "discover": "Discover"
}

$(function () {

    window.M2 = window.M2 || {};
    window.M2.ManageOrder = {};

    const setSaveAddressBtn = () => {
        saveAddressBtn = new SaveButton("#editShipping input[type='submit']").onClick(
            (e) => {
                const $btn = $(e.currentTarget);
                modals.handleModalFormErrors($btn);
            }
        );
    }

    const setCancelOrderBtn = () => {
        cancelOrderBtn = new SaveButton(".cancel-order-container input[type='submit']", {
            "savingLabel": "Canceling",
            "savedLabel": "Canceled"
        }).onClick(
            (e) => {
                const $btn = $(e.currentTarget);
                modals.handleModalFormErrors($btn);
            }
        );
    }

    const refreshContent = (url, target, btn) => {
        $.ajax({
            url: url
        }).done(function (data) {
            window.parent.$(target).html(data);
            btn.setState("saved", 1000, () => {
                modals.closeParent();
            });
        });
    }

    const setModals = () => {
        modals = new M2Modal({
            isIframe: true,
            defaultModalSize: "modal-lg",
            iframeResizeDelay: 300
        });
    }

    const setCreditCardFields = () => {
        if ($("#CreditCardNumber").length) {
                $("#CreditCardNumber").validateCreditCard(function (result) {
                    const $cc_wrapper = $(this).closest(".cc-wrapper");
                    const type = result.card_type;
                    $cc_wrapper.attr("data-card-type", 'blank');

                    const $cc_type = $(".creditCardType");

                    if (type) {
                        const creditCardName = type.name;
                        $cc_wrapper.attr("data-card-type", creditCardName);
                        $cc_type.val(cardTypes[creditCardName]);
                    }
                },
                {
                    accept: ["visa", "mastercard", "amex", "discover"]
                });
        }
    }

    const resendCode = (url) => {
        $.ajax({
            url: url
        }).done(function (data) {
            if (data.success == true) {
                setUpdateCountdown();
            } else {
                alert("There was a problem sending your code, please try again later.");
            }
        });
    }

    const setResendCode = () => {
        $(document).on("click", ".resend-code", function (e) {
            e.preventDefault();
            const codeResendUrl = $(this).data("resend-verification-url");
            resendCode(codeResendUrl);
        });
    }

    const setUpdateCountdown = () => {
        const $resendCodeContainer = $(".resend-code-container");
        $resendCodeContainer.attr("data-resend-code-state", "closed");

        const updateCountdown = setInterval(() => {
            const $remainingTime = $('#remaining-time');
            let codeResendLimit = parseInt($remainingTime.text());
            const newTime = codeResendLimit - 1;
            if (newTime === 0) {
                $resendCodeContainer.attr("data-resend-code-state", "open");
                clearInterval(updateCountdown);
            }
            $remainingTime.text(newTime);
        }, 1000);
    };

    const registerOnOrderLookup = () => {
        window.onOrderLookup = onOrderLookup;
    }

    const onOrderLookup = () => {
        const $form = $("#order-lookup-form");
        if ($form.valid()) {
            $form.trigger("submit");
        }
        // eslint-disable-next-line no-undef
        if (grecaptcha !== undefined) grecaptcha.reset();
    }

    const handleModelOrderUpdate = ($container) => {
        $.validator.unobtrusive.parse($container);
        const $success = $("#HiddenSuccess");
        const isSuccess = $success.val() == "True" ? true : false;
        const refreshUrl = $success.data("refresh-url");

        if (isSuccess) {
            setSaveAddressBtn();
            refreshContent(refreshUrl, ".manage-order-container", saveAddressBtn);
        } else {
            modals.resizeParentIframe();
        }
    }

    M2.ManageOrder = {
        init: () => {
            setCreditCardFields();
            setSaveAddressBtn();
            setCancelOrderBtn();
            setModals();
            setResendCode();
            registerOnOrderLookup();
        },
        onShippingAddressSave: () => {
            handleModelOrderUpdate($("#editShipping"));
        },
        onBillingAddressSave: () => {
            handleModelOrderUpdate($("#editBilling"));
        },
        onAutoRenewalSave: () => {
            handleModelOrderUpdate($("#editAutoRenewal"));
        },
        onCancelOrder: () => {
            $.validator.unobtrusive.parse($("#cancelOrder"));
            const $success = $("#HiddenSuccess");
            const isSuccess = $success.val() == "True" ? true : false;
            const redirectUrl = $success.data("refresh-url");

            if (isSuccess) {
                setCancelOrderBtn();
                cancelOrderBtn.setState("saved", 1000, () => {
                    window.parent.location.href = redirectUrl;
                });
            } else {
                modals.resizeParentIframe();
            }

        }
    }
    M2.ManageOrder.init();
});